<template>
    <v-dialog v-model="dialog" width="600px">
        <v-card>
            <div class="card-body ">
                <!--begin::Contact-->
                <div class="pt-2 pb-2">
                    <div class="d-flex align-items-center justify-content-between mb-1">
                        <span class="font-weight-bold mr-1">Full name:</span>
                        <span class="font-weight-regular">{{ examiner.full_name ? examiner.full_name : 'NA' }}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-1" v-if="examiner.phone_format">
                        <span class="font-weight-bold mr-1">Phone:</span>
                        <span class="font-weight-regular">{{ examiner.phone_format ? examiner.phone_format : 'NA' }}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-1" v-if="examiner.mobile_format">
                        <span class="font-weight-bold mr-1">Mobile:</span>
                        <span class="font-weight-regular">{{ examiner.mobile_format ? examiner.mobile_format : 'NA' }}</span>
                    </div>
                    <div class="d-flex align-items-center justify-content-between mb-1">
                        <span class="font-weight-bold mr-1">Email:</span>
                        <span class="font-weight-regular">{{ examiner.email ? examiner.email : 'NA' }}</span>
                    </div>
                    <div class="mb-1 mt-5 jumbotron py-2" v-if="examiner.billing_address">
                        <div class="font-weight-bold">Billing address:</div>
                        <div v-html="examiner.billing_address"></div>
                    </div>
                    <div class="mb-1 mt-5 jumbotron py-2" v-if="examiner.postal_address">
                        <div class="font-weight-bold">Postal address:</div>
                        <div v-html="examiner.postal_address"></div>
                    </div>
                </div>
            </div>
            <v-card-actions class="pr-8">
                <v-spacer></v-spacer>
                <v-btn
                    x-large
                    text
                    @click="dialog=false;">
                Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: false,
            examiner: null
        }
    },
    methods: {
        openDialog(examiner) {
            this.dialog = true;
            this.examiner = examiner;
        }
    }
}
</script>