<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Other examiners</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Other examiners
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">


            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">

                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-on:keyup.enter="searchExaminer"
                    @input="search.first_name = $event !== null ? $event : ''" label="First name"
                    v-model="search.first_name" outlined dense clearable>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-on:keyup.enter="searchExaminer"
                    @input="search.last_name = $event !== null ? $event : ''" label="Last name" v-model="search.last_name"
                    outlined dense clearable>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-on:keyup.enter="searchExaminer" @input="search.email = $event !== null ? $event : ''"
                    label="Email" v-model="search.email" outlined dense clearable>
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="4">
                  <v-text-field v-on:keyup.enter="searchExaminer" @input="search.mobile = $event !== null ? $event : ''"
                    label="Mobile No." v-model="search.mobile" outlined dense clearable>
                  </v-text-field>
                </v-col>

                <v-col class="d-flex" cols="12" sm="6" md="4">
                  <v-select :items="status" v-on:keyup.enter="searchExaminer" v-model="search.is_active" label="Status"
                    item-text="name" item-value="value" outlined clearable dense></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="12" class="text-right">
                  <v-btn :loading="isLoading" @click="searchExaminer" class="btn btn-primary btn-search">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>

              </div>
            </div>


            <div class="table-responsive" style="table-layout: fixed">
              <v-skeleton-loader type="table-thead" v-if="isLoading">
              </v-skeleton-loader>

              <v-skeleton-loader v-if="isLoading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table">
                <thead>
                  <tr class="text-left">
                    <th class="px-3">Surname</th>
                    <th class="px-3">First name</th>
                    <th class="px-3">Unique IDs </th>
                    <th class="px-3">Email</th>
                    <th class="px-3">Phone/mobile</th>
                    <th class="px-3">SCORE</th>
                  </tr>
                </thead>
                <tbody>
                  <template>
                    <tr v-if="examiners.length > 0" v-for="item in examiners">
                      <td class="px-3">
                        <a @click="quickView(item)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                          {{ item.last_name }}
                        </a>
                        <div>
                          <span class="badge"
                            v-bind:class="{ 'badge-success': item.is_active_examiner, 'badge-danger': !item.is_active_examiner }">{{
                              item.examiner_status_text }}</span>
                        </div>
                      </td>
                      <td>
                        <span class="mb-1" v-copy="item.first_name">
                          {{ item.first_name }}
                        </span>
                      </td>
                      <td>
                        <span class="" v-copy="item.unique_identifier">
                          {{ item.unique_identifier ? item.unique_identifier : 'NA' }}
                        </span>
                      </td>
                      <td style="max-width: 150px !important; white-space: pre-wrap;">
                        <a class="text-info mb-1" :href="'mailto:' + item.email">
                          {{ item.email ? item.email : 'NA' }}
                        </a>
                      </td>
                      <td class="text-left">
                        <a class="text-info mb-1" :href="'tel:' + item.preferred_phone">
                          {{ item.preferred_phone }}
                        </a>
                      </td>
                      <td style="max-width: 150px !important; white-space: pre-wrap;">
                        {{ item.score_short_name ? item.score_short_name : 'NA' }} {{ item.examining_scores }}
                      </td>
                    </tr>
                    <tr v-if="examiners.length == 0">
                      <td colspan="7" class="text-center">
                        <strong>No items added</strong>
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
              <b-pagination :disabled="isLoading" v-if="examiners.length > 0" class="pull-right mt-7"
                @input="getOtherExaminers" v-model="page" :total-rows="total" :per-page="perPage" first-number
                last-number></b-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>

    <quick-view ref="view-summary"></quick-view>

  </v-app>
</template>
<script>

import ExaminerService from "@/services/user/examiner/ExaminerService";
import QuickView from "./QuickView";

const examiner = new ExaminerService();

export default {
  data() {
    return {
      search: {
        first_name: '',
        last_name: '',
        is_active: '',
        type: '',
        email: '',
        mobile: '',
      },
      status: [
        { name: 'Active', value: 1 },
        { name: 'Inactive', value: 0 },
      ],
      isLoading: false,
      examiners: [],
      total: null,
      perPage: null,
      page: null,
    }
  },
  components:{
    QuickView
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
  },
  methods: {
    getOtherExaminers() {
      this.isLoading = true;
      examiner
        .getOtherExaminers(this.search, this.page)
        .then(response => {
          this.examiners = response.data.data;
          this.page = response.data.meta.current_page;
          this.total = response.data.meta.total;
          this.perPage = response.data.meta.per_page;
          this.isLoading = false;
        })
        .catch((err) => {
          this.$snotify.error('Oops looks like something went wrong.');
          this.searchFieldDialog = false;
          this.isLoading = false;
        });
    },
    searchExaminer() {
      this.page = 1;
      this.search.is_search = true;
      this.getOtherExaminers();
    },
    quickView(examiner) {
      this.$refs['view-summary'].openDialog(examiner);
    }
  },
  mounted() {
    this.getOtherExaminers();
  }
}
</script>